import { api } from "@/config"
import { handleFailedResponse } from "@/helpers/common"
import { values, transform, forEach } from "lodash-es"

const prepareInventoryNotificationSettingItems = settingItems => {
  return transform(
    values(settingItems),
    (array, { id: matching_id, car_classes }) => {
      forEach(car_classes, ({ id: car_class_id, enabled: active, alert_threshold }) => {
        array.push({ matching_id, car_class_id, active, alert_threshold })
      })

      return array
    },
    []
  )
}

const prepareEmailNotificationSetting = ({ setting_items, ...rest }) => {
  const items = prepareInventoryNotificationSettingItems(setting_items)
  return { items, ...rest }
}

export default ({ baseURI }) => ({
  FETCH_ITEM: async ({ commit }, id) => {
    commit("SET_POPUP_LOADING", true)
    const response = await api.get(`${baseURI}/${id}`)

    commit("SET_ITEM", response.data.data)
    commit("SET_POPUP_LOADING", false)
  },

  FETCH_NEW_ITEM: async ({ commit }) => {
    commit("SET_POPUP_LOADING", true)

    const response = await api.get(`${baseURI}/new`)

    commit("SET_ITEM", response.data.data)
    commit("SET_POPUP_LOADING", false)
  },

  UPDATE_ITEM: async ({ commit, state: { item } }) => {
    const setting = prepareEmailNotificationSetting(item)
    commit("SET_POPUP_LOADING", true)

    try {
      const response = await api.put(`${baseURI}/${item.id}`, { ...setting })
      commit("SET_ITEMS", response.data.data.items)
    } catch (error) {
      handleFailedResponse(error)
    } finally {
      commit("SET_POPUP_LOADING", false)
    }
  },

  CREATE_ITEM: async ({ commit, state: { item } }) => {
    const setting = prepareEmailNotificationSetting(item)
    commit("SET_POPUP_LOADING", true)

    try {
      const response = await api.post(baseURI, setting)
      commit("SET_ITEMS", response.data.data.items)
    } catch (error) {
      handleFailedResponse(error)
    } finally {
      commit("SET_POPUP_LOADING", false)
    }
  }
})
