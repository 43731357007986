<template lang="pug">
  .email-notifications
    AppOverlayLoader.loader(:state="loading")
    TopBar
    .table-wrapper
      EmailNotificationTable(
        :notifications="notifications"
        @delete-notification="deleteNotification"
      )
      AppPagination(
        :current-page="pagination.current_page"
        :per-page="pagination.per_page"
        :total="pagination.total_count"
        @change-pagination-data="changePagination"
      )
</template>

<script>
  // store modules
  import emailNotificationsModule from "@/config/store/email_notifications"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // components
  import EmailNotificationTable from "./Table"
  import TopBar from "./TopBar"

  const emailNotificationsMixin = withStoreModule(emailNotificationsModule, {
    resetState: true,
    name: "emailNotifications",
    readers: {
      notifications: "items",
      loading: "loading",
      pagination: "pagination"
    },
    actions: {
      fetchItems: "FETCH_ITEMS",
      deleteNotification: "DELETE_ITEM"
    },
    mutations: {
      setPagination: "SET_PAGINATION_DATA"
    }
  })

  export default {
    components: {
      EmailNotificationTable,
      TopBar,
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [emailNotificationsMixin],

    mounted() {
      this.fetchItems()
    },

    methods: {
      changePagination(paginationData) {
        this.setPagination({ ...this.pagination, ...paginationData })
        this.fetchItems()
      }
    }
  }
</script>
