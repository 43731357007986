<template lang="pug">
  .email-notifications-form
    AppOverlayLoader(:state="loading")
    .container-fluid
      .row.email-notifications-field
        .col.col-sm-3.label
          FormFieldLabel(
            :title="$t('email_notifications.field.email')"
            required
          )
        .col.col-sm-6.email
          BFormInput(
            type="text"
            name="email"
            v-model="$v.setting.email.$model"
            :class="{ invalid: $v.setting.email.$error }"
          )
          BFormInvalidFeedback(v-if="$v.setting.email.$error") {{ $t("validations.invalid_email") }}
      .row.email-notifications-field
        .col.col-sm-3.label
          FormFieldLabel(
            :title="$t('email_notifications.field.contents')"
            required
          )
        .col.col-sm-6
          AppDropdown(
            checkbox
            batch-select
            multiple
            allow-empty
            title-key="name"
            value-key="type"
            :class="{ invalid: $v.setting.content_types.$error }"
            :value="selectedContents"
            :items="contents"
            @select="setContentTypes"
          )
    .email-notifications-table(v-if="!isProduction")
      .email-notifications-content
        table
          thead
            tr
              th.item-name.on-off
                span {{ $t("email_notifications.table_header.on_off") }}
              th.item-name.shop-car-class
                span {{ $t("email_notifications.table_header.shop_car_class") }}
              th.item-name
                span {{ $t("email_notifications.table_header.alert_threshold") }}
          tbody
            template(v-for="(settingItem, settingItemIndex) in $v.setting.setting_items.$each.$iter")
              SettingItemRow(
                v-if="!isEmpty(settingItem.car_classes)"
                :key="settingItem.id.$model"
                :setting-item="settingItem"
                :active="settingItemToggler(settingItem.id.$model).state"
                @click="settingItemToggler(settingItem.id.$model).toggle()"
                @toggle-enabled="setSettngItemEnabled(settingItemIndex, !settingItem.enabled.$model)"
              )
              CarClassItemRow(
                v-if="settingItemToggler(settingItem.id.$model).state"
                v-for="(carClass, index) in settingItem.car_classes.$each.$iter"
                :key="`${settingItem.id.$model}-${carClass.id.$model}`"
                :carClass="carClass"
                @change-car-class="changeCarClass({ item: $event, settingItemIndex, index })"
              )
    .d-flex.justify-content-end
      AppSaveButton(
        short
        title="actions.save_changes"
        @save="updateSetting"
      )
</template>

<script>
  import { EMAIL_REGEXP } from "@/config/constants"
  import { get, isEmpty, reduce, values, filter, forEach, map } from "lodash-es"
  import { toggleize } from "@/helpers/toggler"
  import withStoreModule from "@/mixins/withStoreModule"
  import withScrollTop from "@/mixins/withScrollTop"
  import emailNotificationsModule from "@/config/store/email_notifications"
  import withValidations from "@/mixins/withValidations"
  import { getEmailNotificationContents } from "@/config/translations_helper"

  const emailNotificationsMixin = withStoreModule(emailNotificationsModule, {
    name: "emailNotifications",
    readers: {
      setting: "item",
      loading: "popupLoading"
    },
    actions: {
      fetchSetting: "FETCH_ITEM",
      fetchNewItem: "FETCH_NEW_ITEM",
      updateSettingAction: "UPDATE_ITEM",
      createSettingAction: "CREATE_ITEM"
    },
    mutations: {
      setCarClassItemByIndex: "SET_CAR_CLASS_ITEM_BY_INDEX"
    }
  })

  const validationsMixin = withValidations(({ required, requiredIf, minValue, integer }) => ({
    setting: {
      email: {
        required,
        emailCheck: value => isEmpty(value) || EMAIL_REGEXP.test(value)
      },
      content_types: {
        required
      },
      setting_items: {
        $each: {
          id: { required },
          name: { required },
          enabled: { required },
          car_classes: {
            $each: {
              id: { required },
              name: { required },
              enabled: { required },
              alert_threshold: {
                required: requiredIf(function(nested) {
                  return nested.enabled
                }),
                integer,
                minValue: minValue(1)
              }
            }
          }
        }
      }
    }
  }))

  export default {
    props: {
      notificationId: {
        type: Number,
        required: false
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      SettingItemRow: () => import("./SettingItemRow"),
      CarClassItemRow: () => import("./CarClassItemRow"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [withScrollTop, validationsMixin, emailNotificationsMixin],

    created() {
      this.setDefaultToogle()
      if (this.isEdit) {
        this.fetchSetting(this.notificationId)
      } else {
        this.fetchNewItem()
      }
    },

    methods: {
      isEmpty,

      settingItemToggler(itemId) {
        return get(this.settingItemTogglers, itemId)
      },

      setContentTypes(contents) {
        this.setting.content_types = map(contents, "type")
      },

      setSettngItemEnabled(settingItemIndex, enabled) {
        this.setting.setting_items[settingItemIndex].enabled = enabled
        this.bulkChangeCarClassesEnabled(settingItemIndex, enabled)
      },

      bulkChangeCarClassesEnabled(settingItemIndex, enabled) {
        this.setting.setting_items[settingItemIndex].car_classes.forEach((carClass, index) => {
          this.changeCarClass({ item: { ...carClass, enabled: enabled }, settingItemIndex, index })
        })
      },

      changeCarClass(carClass) {
        this.setCarClassItemByIndex(carClass)
        if (carClass.item.enabled) {
          this.setting.setting_items[carClass.settingItemIndex].enabled = true
        }
      },

      updateSetting() {
        this.validateAttributes()

        if (this.isValid) {
          this.saveAction.then(() => {
            this.$emit("close")
          })
        } else {
          this.expandInvalidItems()
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", block: "center" })
          })
        }
      },

      expandInvalidItems() {
        this.invalidSettingItems.forEach(item => {
          this.settingItemToggler(item.id.$model).state = true
        })
      },

      setDefaultToogle() {
        forEach(this.settingItemTogglers, toggle => {
          toggle.disable()
        })
      }
    },

    computed: {
      // TODO: remove when the feature is production ready
      isProduction() {
        return process.env.VUE_APP_ENV === "production"
      },

      isEdit({ notificationId }) {
        return !!notificationId
      },

      saveAction({ isEdit, updateSettingAction, createSettingAction }) {
        return isEdit ? updateSettingAction() : createSettingAction()
      },

      contents() {
        return getEmailNotificationContents(this.$i18n)
      },

      selectedContents() {
        return filter(this.contents, ({ type }) => this.setting.content_types.includes(type))
      },

      invalidSettingItems() {
        return filter(values(this.$v.setting.setting_items.$each), "$error")
      },

      settingItemTogglers() {
        return reduce(
          this.setting.setting_items,
          (obj, { id }) => {
            obj[id] = toggleize(`setting-item-toggler-${id}`, false)

            return obj
          },
          {}
        )
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .email-notifications
    &-form
      min-width: 700px

    &-table
      +matchings-table-header
      margin-top: 10px
      margin-bottom: 10px
      overflow-y: auto
      max-height: 400px

      ::v-deep
        table
          width: 100%
          margin-bottom: 20px

          th
            &.on-off
              +cell-width(100px)
            &.shop-car-class
              +cell-width(650px)

    &-field
      ::v-deep
      input
        border-color: $default-purple-light-line

        &.invalid
          +default-invalid-input

      .label
        padding: 10px 0
        color: $default-gray

      .email
        padding-left: 22px
        padding-right: 22px
</style>
