import { configPrepare } from "@/helpers/vue-good-table"

const i18nPrefix = "email_notifications.table_header"
const defaults = {
  tdClass: "email-notifications-table-td",
  thClass: "email-notifications-table-th",
  sortable: false
}

export const columns = () =>
  configPrepare(
    [
      "email",
      {
        field: "created_at",
        thClass: "email-notifications-table-th created_at"
      },
      {
        label: "",
        field: "actions",
        sortable: false,
        tdClass: "email-notifications-table-th text-center actions-field"
      }
    ],
    {
      i18nPrefix,
      defaults
    }
  )
