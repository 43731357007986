import StoreItemsModule from "@/config/store/StoreItemsModule"

import extractMutations from "./mutations"
import extractActions from "./actions"

const BASE_URI = "/email_notification_settings"
const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch", "delete"],
  withPagination: true
})

store.mergeState({
  item: {
    email: null,
    content_types: [],
    setting_items: []
  },
  popupLoading: false
})

store.mergeMutations(extractMutations())

const { FETCH_ITEM, UPDATE_ITEM, FETCH_NEW_ITEM, CREATE_ITEM } = extractActions({ baseURI: BASE_URI })

store.mergeActions({ FETCH_ITEM, UPDATE_ITEM, FETCH_NEW_ITEM, CREATE_ITEM })

export default store
